import getFromCdn from '@anm/helpers/getFromCdn';

const lightTheme = {
  textColor: 'var(--gray_900)',
  disabledTextColor: 'var(--gray_300)',
  headerBg: 'var(--white)',
  bodyBg: 'var(--white)',
  broadcastAndFeedsBg: 'var(--gray_20)',
  controlsBg: 'var(--white)',
  tabContentBg: 'var(--white)',
  tabPanelBg: 'var(--gray_10)',
  tabActiveItemBg: 'var(--white)',
  tabIconColorFilter: 'var(--gray_to_black)',
  tabItemHoverBg: 'var(--gray_50)',
  scenesWrapperBg: 'var(--white)',
  inputBorder: '#cbdce5',
  inputBg: 'var(--white)',
  inputShadow: '0 0 10px 0 rgba(255, 255, 255, 0.5)',
  dropdownAndModalBg: 'var(--white)',
  modalBordersColor: 'var(--gray_50)',
  whiteToLightBlueButtonBg: 'var(--white)',
  whiteToLightBlueButtonHoverBg: '#e1f9ff',
  disabledSwitchBg: 'var(--gray_50)',
  selectOptionActiveBg: '#E9EEF1',
  selectOptionHoverBg: 'rgba(9, 188, 228, 0.1)',
  broadcastControlsTextColor: 'var(--gray_500)',
  broadcastControlsHoverBg: 'var(--gray_10)',
  broadcastControlsCaretHoverBg: '#e0e4e6',
  chatGuestMessageBg: 'var(--gray_10)',
  logoImage: `${getFromCdn('images/streaming/logo.svg')}`,
  blackDropdownArrowColorFilter: 'none',
  layoutControlsBg: 'var(--white)',
  layoutControlsImageFilter: 'none',
  layoutControlsDropdownBg: 'var(--gray_20)',
  tabItemSeparatorBg: 'rgba(0, 56, 87, 0.2)',
  permissionItemActiveBg: 'var(--blue_20)',
  permissionItemHoverBg: 'var(--gray_10)',
  radioButtonCircleBorderColor: 'var(--gray_50)',
  radioButtonCircleBg: 'var(--white)',
  webkitScrollbarBg: 'var(--white)',
  webkitScrollbarTrackBg: 'var(--white)',
  webkitScrollbarThumbBg: '#babac0',
  liveChatTabBgActiveItem: 'var(--blue_20)',
  liveChatTabBgHoverItem: 'var(--blue_100_10)',
  dropdownArrowSideBg: 'var(--gray_50)',
  destinationHeaderBlockBg: 'var(--gray_40)',
  brandSelectorHoverBg: '#F4F6F7',
  openScenesButtonTextColorOpenState: 'var(--gray_900)',
  openScenesButtonTextColorCloseState: 'var(--white)',
  openScenesButtonCaretFilterOpenState: 'none',
  openScenesButtonCaretFilterCloseState: 'brightness(999%)',
  openScenesButtonFilterOpenState: 'brightness(999%)',
  openScenesButtonFilterCloseState: 'none',
  brandMenuItemBoxShadow: '0px 5px 20px rgba(1, 83, 130, 0.2);',
  brandMenuHoverBg: 'var(--gray_50)',
  errorPageGradient: 'linear-gradient(to top, #def5ff, #ffffff)',
  addBrandBrandPlaceholder: '#8B9499',
  addMediaTabTextColor: 'rgb(145, 158, 170)',
  stockSearchHeaderBg: '#e1f9ff',
  activeDestinationTabBg: '#D9F2FF',
  destinationItemHover: '#f4f6f7',
  destinationsEmptyTextColor: '#71777A'
};

export default lightTheme;
