import getFromCdn from '@anm/helpers/getFromCdn';

import { Themes } from '.';

const darkTheme: Themes = {
  textColor: 'var(--gray_20)',
  disabledTextColor: 'var(--gray_100)',
  headerBg: 'var(--gray_800)',
  bodyBg: 'var(--gray_950)',
  broadcastAndFeedsBg: 'var(--gray_950)',
  controlsBg: 'var(--gray_800)',
  tabContentBg: 'var(--gray_900)',
  tabPanelBg: 'var(--gray_800)',
  tabActiveItemBg: 'var(--gray_600)',
  tabIconColorFilter: 'var(--any_to_gray_20)',
  tabItemHoverBg: 'var(--gray_500)',
  scenesWrapperBg: 'var(--gray_800)',
  inputBorder: 'var(--gray_600)',
  inputBg: 'transparent',
  inputShadow: 'none',
  dropdownAndModalBg: 'var(--gray_700)',
  modalBordersColor: 'var(--gray_600)',
  whiteToLightBlueButtonBg: 'var(--gray_700)',
  whiteToLightBlueButtonHoverBg: 'var(--gray_600)',
  disabledSwitchBg: 'var(--gray_500)',
  selectOptionActiveBg: 'var(--gray_500)',
  selectOptionHoverBg: 'var(--gray_600)',
  broadcastControlsTextColor: 'var(--gray_400)',
  broadcastControlsHoverBg: 'var(--gray_700)',
  broadcastControlsCaretHoverBg: 'var(--gray_600)',
  chatGuestMessageBg: 'var(--gray_700)',
  logoImage: `${getFromCdn('images/streaming/logo-dark-theme.svg')}`,
  blackDropdownArrowColorFilter: 'var(--any_to_gray_400)',
  layoutControlsBg: 'var(--gray_400)',
  layoutControlsImageFilter: 'var(--any_to_gray_600)',
  layoutControlsDropdownBg: 'var(--gray_600)',
  tabItemSeparatorBg: 'var(--gray_600)',
  permissionItemActiveBg: 'var(--gray_500)',
  permissionItemHoverBg: 'var(--gray_600)',
  radioButtonCircleBorderColor: 'var(--gray_500)',
  radioButtonCircleBg: 'var(--gray_700)',
  webkitScrollbarBg: 'var(--gray_900)',
  webkitScrollbarTrackBg: 'var(--gray_900)',
  webkitScrollbarThumbBg: 'var(--gray_950)',
  liveChatTabBgActiveItem: 'var(--gray_600)',
  liveChatTabBgHoverItem: 'var(--gray_500)',
  dropdownArrowSideBg: 'var(--gray_700)',
  destinationHeaderBlockBg: 'var(--gray_600)',
  brandSelectorHoverBg: 'var(--gray_600)',
  openScenesButtonTextColorOpenState: 'var(--gray_20)',
  openScenesButtonCaretFilterOpenState: 'none',
  openScenesButtonTextColorCloseState: 'var(--white)',
  openScenesButtonCaretFilterCloseState: 'brightness(999%)',
  openScenesButtonFilterOpenState: 'var(--any_to_gray_800)',
  openScenesButtonFilterCloseState: 'none',
  brandMenuItemBoxShadow: '0px 2px 10px var(--gray_600);',
  brandMenuHoverBg: 'var(--gray_700)',
  errorPageGradient: 'linear-gradient(to top, var(--gray_950), var(--gray_700))',
  addBrandBrandPlaceholder: 'var(--gray_20)',
  addMediaTabTextColor: 'var(--gray_500)',
  stockSearchHeaderBg: 'var(--gray_400)',
  activeDestinationTabBg: '#71777A',
  destinationItemHover: '#575c5e',
  destinationsEmptyTextColor: '#fff'
};

export default darkTheme;
