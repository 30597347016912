const prefix = 'collected_info';

export const createCollectInfoContainer = (containerName: string) => {
  const storeKey = `${prefix}/${containerName}`;

  const get = () => JSON.parse(localStorage.getItem(storeKey) || '{}');

  const add = <T extends {}>(newInfo: T) => {
    const prevAddedInfo = get();

    const data = { ...prevAddedInfo, ...newInfo };
    localStorage.setItem(storeKey, JSON.stringify(data));
  };

  const flush = () => {
    const info = get();
    localStorage.removeItem(storeKey);
    return info;
  };

  return [add, flush] as const;
};
